.divCenter {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 10px;

    position: absolute;
    width: 988px;
    height: 647px;

    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;

    left: 226px;
    top: 218px;

}

.divBottom {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;

    position: absolute;
    width: 1114px;
    height: 117px;
    left: 163px;
    top: 865px;

    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;
}

.card {
    justify-content: center;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    border: 0.01px solid #EFEFEF !important;
    transition: 0.3s;
    width: 70%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    margin-top: 10px;
    /* height: 647px; */
    /* Added */
}

.card_content {
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    margin-top: 10px;
    /* Added */
}

.cardData {

    /* justify-content: center; */
    border-radius: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    border: 0.01px solid #EFEFEF !important;
    transition: 0.3s;
    width: 100%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    margin-top: 10px;
    text-align:left;
   
    /* Added */
}

.card_bottom {
   
    border-radius: 10px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    border: 0.01px solid #EFEFEF !important;
    transition: 0.3s;
    width: 80%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: white;
   padding: 10px;
}

/* .card:hover,
.card_bottom:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

.container {
    padding: 2px 16px;
    text-align: center;
    line-height: normal;
}

.txtCss {
    width: 100%;
    height: 46px;
    padding: 0.375rem 0.75rem;
    margin: 8px 0;
    box-sizing: border-box;
    border: 0.5px solid #EFEFEF;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
}

.header1 {
    /* text-align: center; */
    /* width: 199px; */
    height: 25px;

    /* Heading 3/Medium */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    /* line-height: 25px; */
    /* identical to box height, or 132% */


    /* Trout */

    color: #495057;


    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.header2 {
    /* width: 399px; */
    /* height: 33px; */

    /* Heading 2/Medium */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    /* line-height: 33px; */
    /* identical to box height, or 132% */


    /* Trout */

    color: #495057;

    /* Inside auto layout */

    /* flex: none;
    order: 1;
    flex-grow: 0; */
}

.header3 {
    /* width: 171px; */
    /* height: 44px; */

    /* Heading 1/Bold */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    /* line-height: 44px; */
    /* identical to box height, or 133% */

    /* text-align: center; */

    /* Trout */

    color: #495057;


    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.subLabel {
    /* width: 83px; */
    height: 19px;

    /* Body/Medium */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 136% */


    /* Trout */

    color: #495057;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

/* Top nav */



* {
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
    margin: 0;
    min-height: 100vh;
    background-color: #F3F3F3;
    
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}


h2,
h3,
a {
    color: #34495e;
}

a {
    text-decoration: none;
}



.logo {
    margin: 0;
    font-size: 1.45em;
}

.main-nav {
    margin-right: 5px;

}

.logo a,
.main-nav a {
    padding: 10px 15px;
    text-transform: uppercase;
    text-align: center;
    display: block;
}

.main-nav a {
    color: #34495e;
    font-size: .99em;
}

.main-nav a:hover {
    color: #718daa;
}



.header {
    width: 100%;
    padding-top: .5em;
    padding-bottom: .5em;
    /* border: 1px solid #a2a2a2; */
    background-color: #FFFFFF;
    /* -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75); */
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
    /* -webkit-border-radius: 5px;
	-moz-border-radius: 5px; */
    /* border-radius: 5px; */
}


/* ================================= 
  Media Queries
==================================== */




@media (min-width: 100vh) {

    .header,
    .main-nav {
        display: flex;
    }

    .header {
        flex-direction: column;
        align-items: center;
        /* .header{ */
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        /* } */
    }

}

@media (min-width: 100vh) {
    .header {
        flex-direction: row;
        justify-content: space-between;
    }

}

.logo_img {
    /* position: absolute; */
    width: 136px;
    height: 76px;
    /* left: 50px;
    top: 96px; */
}

/* Button */
.btnCss {
    /* display: flex;
    flex-direction: row; */
    justify-content: space-between;
    align-items: center;
    padding: 10.5px 10px;
    gap: 10px;

    width: 292px;
    height: 56px;

    background: linear-gradient(104.57deg, #7389F5 -2%, #455DDA 100.62%);
    border-radius: 4px;
    color: #FFFFFF;
    border: none;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
}

div.col-sm-8 {
    text-align: left;
}

div.col-sm-4 {
    margin: auto;
    text-align: center;
   
}

table {
    width: 100%;
}

.checkboxHeader {
    margin-left: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* text-decoration-line: underline; */

    color: #111827;
}

.check-with-label:checked + .checkboxHeader {
    text-decoration-line: underline;
  }

.checkboxFooter {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 136% */


    /* Text / Lightest */

    color: #999999;
}

/* .check-with-label:checked + .checkboxFooter {
    color: #111827 !important;
    text-decoration-line: underline;
  } */

.txtlabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 136% */


    /* Trout */

    color: #495057;
}

.cardMessage {

    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    /* transition: 0.3s;
     width: 50%;
     margin: auto; */
    /* Added */
    /* float: none; */
    /* Added */
    /* margin-bottom: 10px;
     margin-top: 10px;  */
    /* Added */
    /* margin-top: 20%; */
    /* margin: auto; */
    /* float: left; */
    /* margin-left: 10px; */
    /* margin: auto;
     width: 40%;
     height: 333.5px; */
    background-color: white;


    width: 40%;
    height: 333.5px;
    margin: 0;
    position: relative;
    text-align: center;
}

.cardMessage div {
    margin-top: 30px;
}

.card-outer {
    justify-content: center;
    border-radius: 10px;
    border: 0.01px solid #EFEFEF !important;
    transition: 0.3s;
    min-width: 100%;
    margin: 0 auto;
    float: none;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: white;
    padding: 12px;
   
}

.labelMessage2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.6;
    /* or 132% */

    text-align: center;

    color: #808080;
    word-break: break-all;
}

.labelMessage1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    word-wrap: break-word;
    line-height: 1.6;
    /* identical to box height, or 132% */

    text-align: center;

    color: #808080;
}

.header3failed {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 1.6;
    color: #C31162;
}

.txtCss:focus {
    border-color: #33C7FF;
    box-shadow: 0 1px 1px white inset, 0 0 8px #33C7FF;
    outline: 0 none;
}

.txtCourse {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 25px;
    /* identical to box height, or 132% */


    color: #808080;
}

.txtSavePrice {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 25px;
    /* identical to box height, or 132% */
    color: #808080;
}

/* .row
{
    vertical-align: middle;
} */

.main-card
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* min-width: 98%; */
    width:60%;
    padding:8px;
}

input[type=number] {
    appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.txtlabel::after { 
    content: "*";
    color: red;
}

@media only screen and (max-width: 600px) {
    .main-card
    {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 98%;
        width:98%;
        padding:8px;
    }
    
  }