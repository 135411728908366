.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.undisable{
  opacity: 1;
  cursor: pointer !important;
}
.diableBtn{
  cursor: no-drop !important;
  opacity: 0.5;
}
.btn-pay {
  box-sizing: border-box;
  position: relative;
  display: block;
  min-height: 38px;
  padding: 10px;
  line-height: 18px;
  font-weight: 600;
  font-size: 14px;
  font-family: Lato, Muli, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  word-break: break-word;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  text-decoration: none;
  background-color: rgb(75, 177, 121);
  width: 100%;
  border: none;
}
