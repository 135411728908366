@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,800&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
*,
p {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.check {
  font-size: 1vw;
  margin-top: 2vw;
}
.successMsg {
  color: #4cc491;
  font-size: 1vw;
  margin-top: 8px;
}
.ErrorMsg {
  color: crimson;
  font-size: 1vw;
  margin-top: 8px;
}
.imgDiv {
  height: 3vw;
  width: 10vw;
  margin-bottom: 1.5vw;
}
.background-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background: #4568dc;
  background: -webkit-linear-gradient(to right, #4568dc, #b06ab3);
  background: linear-gradient(to right, #4568dc, #b06ab3);
}
.input-container {
  height: 3vw;
  position: relative;
  width: 100%;
}
.step {
  font-size: 1vw;
  font-weight: 700;
}
.description {
  font-size: 1vw;
  margin-top: 1vw;
}
.descriptionHeading {
  font-size: 2vw;
  margin-top: 1vw;
}
.descrip {
  font-size: 1vw;
  margin-top: 1vw;
  font-weight: 400;
}
.stepHeading {
  font-size: 2vw;
  font-weight: 700;
}

.desDiv {
  padding: 1vw;
  background-color: #d6d6d638;
  border-radius: 1vw;
  margin-top: 1vw;
}
.identify {
  font-size: 1vw;
  font-weight: 600;
  color: #000000;
}

.input {
  background-color: #e8eaf6;
  border-radius: 0.3vw;
  border: 0;
  box-sizing: border-box;
  color: black;
  font-size: 1.5vw;
  height: 100%;
  outline: 0;
  padding: 1vw 1vw;
  width: 50%;
}

.main_modal {
  display: flex;
  width: 80%;
  background: white;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 1vw;
  padding: 1vw 1vw;
  flex-direction: column;
  position: relative;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.main_title {
  display: flex;
  color: black;
  font-size: 2vw;
  line-height: 2vw;
  font-weight: 700;
  margin-bottom: 1vw;
}
.model_form {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
}
.check_for_offer,
.model_form_input {
  display: flex;
  height: max-content;
  font-weight: 500;
}
.model_form_input {
  width: 100%;
  outline: none;
  border: none;
  padding-bottom: 0.2vw;
  font-size: 1vw;
  line-height: 1vw;
}
.check_for_offer {
  width: max-content;
  padding: 0.6vw 1.4vw;
  border: none;
  border-radius: 0.4vw;
  background-color: #5478f3;
  font-size: 1.2vw;
  line-height: 1.8vw;
  margin-top: 1vw;
  cursor: pointer;
  color: #fff;
}
.offer_value_container {
  display: flex;
  width: 100%;
  height: max-content;
  background-color: #4cc491;
  flex-direction: column;
}
.table-container {
  margin-top: 1vw;
  width: 100%;
  overflow-x: auto;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table thead {
  background-color: #303245;
}
.table tbody{
  vertical-align: middle;
}

.table thead tr th {
  font-size: 1.5vw;
  color: white;
  font-weight: 600;
  letter-spacing: 0.02vw;
  padding: 0.5vw;
}
.table tbody tr td {
  font-size: 1.2vw;
  font-weight: 400;
  letter-spacing: 0.02vw;
  padding: 0.5vw;
}

.razorpay-embed-btn{
  margin-top: 20px;
}

@media only screen and (max-width: 768px) {
  .stepHeading {
    font-size: 4vw;
    font-weight: 700;
  }
  .descriptionHeading {
    font-size: 4vw;
    margin-top: 2vw;
  }
  .step {
    font-size: 3vw;
    font-weight: 700;
  }
  .description {
    font-size: 3.5vw;
    margin-top: 2vw;
  }
  .descrip {
    font-size: 3vw;
    margin-top: 2vw;
    font-weight: 400;
  }

  .desDiv {
    padding: 2vw;
    background-color: #d6d6d638;
    border-radius: 1vw;
    margin-top: 2vw;
    min-width: 100%;
  }
  .identify {
    font-size: 3vw;
    font-weight: 600;
    color: #000000;
  }
  .check {
    font-size: 3vw;
    margin-top: 5vw;
  }
  .successMsg {
    color: #4cc491;
    font-size: 3vw;
    margin-top: 1vw;
  }
  .ErrorMsg {
    color: crimson;
    font-size: 3vw;
    margin-top: 1vw;
  }

  .imgDiv {
    height: 6vw;
    width: 20vw;
    margin-bottom: 1vw;
  }
  .payBtn {
    width: 100% !important;
    padding-left: 0 !important;
    text-align: center !important;
    padding-bottom: 4vw !important;
  }

  .background-page {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    background: #4568dc;
    background: -webkit-linear-gradient(to right, #4568dc, #b06ab3);
    background: linear-gradient(to right, #4568dc, #b06ab3);
    padding-top: 4vw;
    padding-bottom: 3vw;
  }
  .table thead {
    display: none;
  }
  .table tbody tr td {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.02vw;
    padding: 1vw;
    text-align: center;
  }
  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }

  /* .table-container {
    margin-top: 5vw;
    width: 80%;
    overflow-x: auto;
  } */

  .table tr {
    margin-bottom: 0.1vw;
  }
  .table tbody{
    vertical-align: middle;
  }

  .table tbody tr td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    font-weight: 700;
    left: 0;
  }

  .input-container {
    height: 8vw;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ic1 {
    margin-top: 1.5vw;
  }

  .input {
    background-color: #e8eaf6;
    border-radius: 0.4vw;
    border: 0;
    box-sizing: border-box;
    color: black;
    font-size: 3vw;
    height: 100%;
    outline: 0;
    padding: 1vw 1vw;
    width: 80%;
  }

  .main_modal {
    display: flex;
    width: 90%;
    background: white;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border-radius: 1vw;
    padding: 1vw 1vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .main_title {
    text-align: center;
    display: flex;
    font-size: 4vw;
    line-height: 4vw;
    /* font-weight: 600; */
    margin-bottom: 2vw;
  }
  .model_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: 100%;
  }
  .check_for_offer,
  .model_form_input {
    display: flex;
    height: max-content;
    font-weight: 400;
  }
  .model_form_input {
    width: 100%;
    outline: none;
    border: none;
    padding-bottom: 1vw;
    font-size: 4vw;
    line-height: 2vw;
    /* font-family: "Montserrat", sans-serif; */
  }
  .check_for_offer {
    border: transparent;
    width: max-content;
    padding: 2vw 2vw;
    border-radius: 1.5vw;
    background-color: #5478f3;
    font-size: 3vw;
    line-height: 2vw;
    margin-top: 2vw;
    cursor: pointer;
    color: #fff;
  }
  .offer_value_container {
    display: flex;
    width: 100%;

    height: max-content;
    flex-direction: column;
  }
}

.d-block {
  display: block;
}

.d-hide {
  display: none;
}
